exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bienvenida-js": () => import("./../../../src/pages/bienvenida.js" /* webpackChunkName: "component---src-pages-bienvenida-js" */),
  "component---src-pages-clubcocinasana-js": () => import("./../../../src/pages/clubcocinasana.js" /* webpackChunkName: "component---src-pages-clubcocinasana-js" */),
  "component---src-pages-cocina-sana-js": () => import("./../../../src/pages/cocina-sana.js" /* webpackChunkName: "component---src-pages-cocina-sana-js" */),
  "component---src-pages-cocina-sana-strapi-noticias-consejos-trucos-slug-js": () => import("./../../../src/pages/cocina-sana/{StrapiNoticiasConsejosTrucos.slug}.js" /* webpackChunkName: "component---src-pages-cocina-sana-strapi-noticias-consejos-trucos-slug-js" */),
  "component---src-pages-consejos-de-salud-js": () => import("./../../../src/pages/consejos-de-salud.js" /* webpackChunkName: "component---src-pages-consejos-de-salud-js" */),
  "component---src-pages-consejos-de-salud-strapi-noticias-consejos-trucos-slug-js": () => import("./../../../src/pages/consejos-de-salud/{StrapiNoticiasConsejosTrucos.slug}.js" /* webpackChunkName: "component---src-pages-consejos-de-salud-strapi-noticias-consejos-trucos-slug-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-herramientas-bajar-de-peso-js": () => import("./../../../src/pages/herramientas/bajar-de-peso.js" /* webpackChunkName: "component---src-pages-herramientas-bajar-de-peso-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-recetas-js": () => import("./../../../src/pages/recetas.js" /* webpackChunkName: "component---src-pages-recetas-js" */),
  "component---src-pages-recetas-strapi-article-slug-js": () => import("./../../../src/pages/recetas/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-recetas-strapi-article-slug-js" */),
  "component---src-pages-reels-js": () => import("./../../../src/pages/reels.js" /* webpackChunkName: "component---src-pages-reels-js" */),
  "component---src-pages-reels-strapi-reels-slug-js": () => import("./../../../src/pages/reels/{StrapiReels.slug}.js" /* webpackChunkName: "component---src-pages-reels-strapi-reels-slug-js" */),
  "component---src-pages-regalo-enviado-js": () => import("./../../../src/pages/regalo-enviado.js" /* webpackChunkName: "component---src-pages-regalo-enviado-js" */),
  "component---src-pages-trucos-de-cocina-js": () => import("./../../../src/pages/trucos-de-cocina.js" /* webpackChunkName: "component---src-pages-trucos-de-cocina-js" */),
  "component---src-pages-trucos-de-cocina-strapi-noticias-consejos-trucos-slug-js": () => import("./../../../src/pages/trucos-de-cocina/{StrapiNoticiasConsejosTrucos.slug}.js" /* webpackChunkName: "component---src-pages-trucos-de-cocina-strapi-noticias-consejos-trucos-slug-js" */)
}

